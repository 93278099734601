<script setup>
defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
})

const removeEmpty = array => array.filter(item => item.url)
</script>

<template>
    <div class="px-8 py-12 xl:px-23">
        <MainTitle :title="data.sectionTitle" />
        <div
            v-for="(event, eventIndex) in data.event"
            :key="'eventloop'+eventIndex"
            class="flex flex-wrap justify-between border-b border-current py-8 text-current first:border-t"
        >
            <div class="order-2 max-w-[75%] lg:order-1">
                <div
                    v-if="event.subtitle"
                    class="mb-4 text-lg uppercase lg:mb-0 lg:text-1xl"
                >
                    <Parallax :animation="false">
                        <div v-html="parseMarkdown(event.subtitle)" />
                    </Parallax>
                </div>
                <div
                    v-if="event.sectionTitle"
                    class="relative h-14 text-9xl lg:h-24 lg:text-12xl"
                >
                    <!-- Absolute to try to improve performance by removing element from document flow -->
                    <CountUp
                        class="absolute left-0 top-0 size-full"
                        :start="0"
                        :number="parseMarkdown(event.sectionTitle)"
                    />
                </div>
            </div>
            <Parallax
                :animation="false"
                class="relative order-1 mb-4 size-34 lg:order-2 lg:mb-0"
                enter-leave-wrapper-classes="size-34 overflow-hidden rounded-full"
            >
                <img
                    class="absolute inset-0 size-full object-cover"
                    :src="event.image[0].url"
                    :srcset="event.image[0].srcset"
                    :alt="event.image[0].alt"
                    loading="lazy"
                >
            </Parallax>
        </div>
        <Parallax :animation="false">
            <Button
                v-for="(link, linkIndex) in removeEmpty(data.link)"
                :key="'link'+linkIndex"
                text="text-current"
                arrow="fill-gold"
                circle="bg-current"
                class="mt-12"
                :to="link.url"
            >
                <span v-html="link.text" />
            </Button>
        </Parallax>
    </div>
</template>
